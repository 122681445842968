/**
 * Golden ratio.
 */
export const websocketKey = process.env.REACT_APP_WEBSOCKET_KEY;
export const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST;
export const websocketPort = process.env.REACT_APP_WEBSOCKET_PORT;
export const websocketPoint = process.env.REACT_APP_WEBSOCKET_AUTHENDPOINT;

export const phi = 1.6,
  serverLocation = process.env.REACT_APP_BASE_URL,
  frontEndURL = process.env.REACT_APP_FRONTEND_URL,
  fileUrl = serverLocation + '/storage',
  invoiceUrl = serverLocation + '/storage/invoices',
  build = '',
  createTermsAndConditionPermissionCode = 'create|Terms And Conditions',
  companySetting = 'company-operation-cost-setting|Company Settings',
  companyBasicSetting = 'basic-settings|Company Settings',
  loginPathName = '/login',
  viewOwnCustomerKycCode = 'view-own|Customer KYC',
  viewAllCustomerKycCode = 'view|Customer KYC',
  viewOwnVendors = 'view-own|Vendors',
  viewAllVendors = 'view|Vendors',
  approveCustomerTier = 'approve|Customer Tier',
  approveCreditCustomer = 'approve-credit|Customer KYC',
  approveCreditVendor = 'approve-credit|Vendor',
  customerKycUrl = `${serverLocation}/customerKyc/`,
  insertCustomerKyc = `${customerKycUrl}insert`,
  selectCustomerKyc = `${customerKycUrl}selectByKey`,
  updateCustomerKyc = `${serverLocation}/api/v1/customer-kycs/update`,
  updateRequestCustomerKyc = `${serverLocation}/api/v1/customer-kyc-update-request`,
  getListOfCustomerKycUpdateRequest = `${serverLocation}/api/v1/customer-kyc-update-request/show`,
  updateCustomerKycUpdateRequest = `${serverLocation}/api/v1/customer-kyc-update-request/update`,
  checkCustomerKycUpdateRequest = `${serverLocation}/api/v1/customer-kyc-update-request/`,
  deleteCustomerKyc = `${customerKycUrl}delete`,
  // deleteSelectedCustomerKyc = `${customerKycUrl}deleteSelected`,
  deleteSelectedCustomerKyc = `${serverLocation}/api/v1/customer-kycs/deleteSelected`,
  countryUrl = `${serverLocation}/country/`,
  cityUrl = `${serverLocation}/city/`,
  selectCityUrl = `${serverLocation}/city/selectByKey`,
  cityInsertUrl = `${serverLocation}/city/insert`,
  cityDeleteUrl = `${serverLocation}/city/delete`,
  cityUpdateUrl = `${serverLocation}/city/update`,
  moduleListUrl = `${serverLocation}/module/`,
  selectCityByKey = `${serverLocation}/city/selectByKey`,
  selectActivity = `${serverLocation}/activity/`,
  departmentRoleListUrl = `${serverLocation}/departmentRole/`,
  portsUrl = `${serverLocation}/port/selectByTypeAndCountry/`,
  selectPortsUrl = `${serverLocation}/port/selectByKey`;

export const imageDisplayUrl = 'http://localhost:8090/download/';

export const ForwardTableStatus = {
  accept: 'Accepted',
  reject: 'Rejected'
};

export const RateWizardPermission = {
  myQuery: 'view|Rate Wizard',
  myCompanyQuery: 'view-my-company-query|Rate Wizard'
};

export const operationModulePermission = {
  addCustomOperationStatus: 'create-custom-status-operation'
};

export const URL = {
  //email check
  emailValidCheck: `${serverLocation}/api/EmailDomainValidationCheck`,
  emailValidCheckMultiple: `${serverLocation}/api/EmailDomainValidationCheckMultiple`,

  //Super Admin

  superAdmin: `${serverLocation}/api/v1/super-admins`,
  superAdminPost: `${serverLocation}/api/v1/super-admins/getData`,
  enableSuperAdmin: `${serverLocation}/api/v1/super-admins/enable`,
  disableSuperAdmin: `${serverLocation}/api/v1/super-admins/disable`,

  enableDisableTier: `${serverLocation}/api/v1/tiers/display-or-not-tier`,
  // service unavailable
  serviceUnavailable: `${serverLocation}/api/v1/service-unavailable`,

  //cannot proceed
  cannotProceed: `${serverLocation}/api/v1/cannot-proceed-further`,

  //store FAQ
  storeFAQ: `${serverLocation}/api/v1/customer-care-services/storeFaqs`,
  getFAQ: `${serverLocation}/api/v1/customer-care-services/allFaqs/loggedInUser`,
  getSuperAdminFAQ: `${serverLocation}/api/v1/customer-care-services/allFaqs/superAdmin`,
  deleteSuperAdminFAQ: `${serverLocation}/api/v1/customer-care-services/deleteFaq`,
  updateSuperAdminFAQ: `${serverLocation}/api/v1/customer-care-services/updateFaqs`,

  //
  //http://127.0.0.1:8000/api/v1/impexo-liscence-agreements
  licenseAgreement: `${serverLocation}/api/v1/impexo-liscence-agreements`,
  updateLicenseAgreement: `${serverLocation}/api/v1/impexo-liscence-agreements/update/`,
  getAllLicenseAgreement: `${serverLocation}/api/v1/impexo-liscence-agreements/getAllImpexoLiscenceAgreement`,
  getSingleLicenseAgreement: `${serverLocation}/api/v1/impexo-liscence-agreements/show/`,
  agreementMakeActive: `${serverLocation}/api/v1/impexo-liscence-agreements/makeActive`,

  //Auth
  loginUrl: `${serverLocation}/api/login`,
  forgotPasswordUrl: `${serverLocation}/api/forgotPassword`,
  forgotPasswordUrlCheck: `${serverLocation}/api/check-forget-password-link`,
  logoutUrl: `${serverLocation}/api/v1/logout`,
  getPersonProfileName: `${serverLocation}/api/v1/getPersonProfileName`,
  checkUsernameAvailability: `${serverLocation}/api/checkUsernameAvailability`,
  handleUserNameExistOuterLink: `${serverLocation}/api/handleUserNameExistOuterLink`,
  getMyNotification: `${serverLocation}/api/v1/getMyNotifications`,
  markAllRead: `${serverLocation}/api/v1/markAllRead`,
  getLimitedNotification: `${serverLocation}/api/v1/getLimitedNotification`,
  readMyNotification: `${serverLocation}/api/v1/readMyNotifications/`,
  changePassword: `${serverLocation}/api/v1/changePassword`,
  updatePassword: `${serverLocation}/api/updatePassword`,
  downloadFile: `${serverLocation}/downloadFile`,
  downloadFileFromUrl: `${serverLocation}/downloadFileFromUrl`,
  downloadZip: `${serverLocation}/downloadZip`,

  //pdfConverter
  convertToPdf: `${serverLocation}/api/convertPdf`,

  // containernumber create:
  CreatecontainerNumber: `${serverLocation}/api/v1/container-numbers`,

  //containernumber for getonly:
  GetcontainerNumber: `${serverLocation}/api/v1/container-numbers`,

  // CONTAINERNUMBER post:
  PostcontainerNumber: `${serverLocation}/api/v1/container-numbers`,

  //containernumber for update:
  UpdatecontainerNumber: `${serverLocation}/api/v1/container-numbers/update`,

  //containernumber for delete:
  DeletecontainerNumber: `${serverLocation}/api/v1/container-numbers/`,

  // Country
  countryUrl: `${serverLocation}/api/v1/countries`,
  countryUrlUnauth: `${serverLocation}/api/countries`,
  countrySearchUrl: `${serverLocation}/api/v1/countries/search`,

  getPaginatedCountry: `${serverLocation}/api/v1/countries/allPaginated`,
  searchCountry: `${serverLocation}/api/v1/countries/search`,

  //City
  cityUrl: `${serverLocation}/api/v1/cities`,
  cityUpdateUrl: `${serverLocation}/api/v1/cities/update`,
  searchCities: `${serverLocation}/api/v1/cities/searchCities`,
  deleteMultipleCities: `${serverLocation}/api/v1/cities/deleteMultiple`,
  getCityById: `${serverLocation}/api/v1/cities/get/`,

  getPaginatedCity: `${serverLocation}/api/v1/cities/allPaginated`,

  getCityByCountryCode: `${serverLocation}/api/v1/cities/getCities`,
  getCityByCountryCodeUnauth: `${serverLocation}/api/cities`,

  //Rate Enquiry  Related Data
  //Container Size Category
  containerSizeCategoriesUrl: `${serverLocation}/api/v1/container-size-categories
  `,
  //Incoterms
  incotermUrl: `${serverLocation}/api/v1/incoterms`,
  //Department:
  departmentListUrl: `${serverLocation}/api/v1/departments`,
  updateDepartnment: `${serverLocation}/api/v1/departments/update`,
  deleteMultipleDepartments: `${serverLocation}/api/v1/departments/deleteMultiple`,
  searchDepartments: `${serverLocation}/api/v1/departments/searchDepartments`,

  //Customer Kyc
  customerKycUrl: `${serverLocation}/api/v1/customer-kycs`,
  selectAllCustomerKycUrl: `${serverLocation}/api/v1/customer-kycs/select`,
  getByUserCustomerKycUrl: `${serverLocation}/api/v1/customer-kycs/getByUser`,
  getApproveListCustomerKyc: `${serverLocation}/api/v1/customer-kyc-approve-log`,
  updateNegotiation: `${serverLocation}/api/v1/customer-kyc-approve-log/updateNegotiation`,
  checkShortNameAvailability: `${serverLocation}/api/v1/customer-kycs/checkShortNameAvailability`,
  checkShortNameAvailabilityOuterLink: `${serverLocation}/api/checkShortNameAvailability`,
  checkUserNameAvailability: `${serverLocation}/api/v1/customer-kycs/checkUserNameAvailability`,
  checkUsernameExists: `${serverLocation}/api/checkUsernameExists`,
  getAllUsersOfCompany: `${serverLocation}/api/v1/customer-kycs/getAllUsers`,
  deleteCustomerKyc: `${serverLocation}/api/v1/customer-kycs/deleteCustomerKyc`,
  generateCopyLinkURL: `${serverLocation}/api/v1/customer-kycs/link/generateCopyLinkURL`,
  resendVerificationEmail: `${serverLocation}/api/v1/customer-kycs/resend-verification-mail`,
  checkKycCompletion: `${serverLocation}/api/v1/customer-kycs/checkKycCompletion`,

  getOwnAndNetworkVendors: `${serverLocation}/api/v1/customer-kycs/get-own-network-vendors`,

  operationRequiredFileForCustomer: `${serverLocation}/api/v1/customer-kycs/company-operation-file-setting/customer`,
  operationRequiredFileForCustomerOuter: `${serverLocation}/api/company-operation-file-setting/customer`,

  insertCompanyOperationRequiredFiles: `${serverLocation}/api/v1/customer-kycs/company-operation-file-setting`,
  deleteCompanyOperationRequiredFiles: `${serverLocation}/api/v1/customer-kycs/company-operation-file-setting/`,
  uploadOperationSettingFile: `${serverLocation}/api/v1/rate-enquiry-operation-setting-file`,
  deleteCustomerUploadedFile: `${serverLocation}/api/v1/rate-enquiry-operation-setting-file/`,
  operationSmsLogs: `${serverLocation}/api/v1/rate-enquirys/sms-logs`,

  getLoggedInUserCustomerKycUrl: `${serverLocation}/api/v1/customer-kycs/getLoggedInUserCompany`,
  getCustomerKycContactDetailUrl: `${serverLocation}/api/v1/customer-kycs/customerContactdetails`,
  searchCustomerKyc: `${serverLocation}/api/v1/customer-kycs/searchCustomerKyc`,
  customerKycUrlOuterLink: `${serverLocation}/api/customerKycLink`,
  sendEmail: `${serverLocation}/api/v1/registerLinkToCustomer`,

  getSentEmailIds: `${serverLocation}/api/v1/email-log`,

  // Vendor Contact Person
  getVendorContactPerson: `${serverLocation}/api/v1/vendors/getVendorContactPerson`,

  // Vendor Approve log
  getVendorApprovalLog: `${serverLocation}/api/v1/customer-kyc-approve-log/vendor`,

  //Customer Kyc Tiers
  customerKycTiersUrl: `${serverLocation}/api/v1/customer-kyc-tiers`,
  getFddVendors: `${serverLocation}/api/v1/customer-kyc-tiers/get/ffd-vendors`,
  customerKycTermsAndConditionUrl: `${serverLocation}/api/v1/customer-kyc-terms-and-conditions`,
  updateCustomerKycTermsAndConditionUrl: `${serverLocation}/api/v1/customer-kyc-terms-and-conditions/update`,
  acceptTermsAndCondition: `${serverLocation}/api/v1/customer-kyc-terms-and-conditions/verifySystem/tnc`,
  acceptCustomerTier: `${serverLocation}/api/v1/customer-kyc-approve-log/updateStatus`,

  //Customer Kyc Designations
  customerKycDesignationUrl: `${serverLocation}/api/v1/customer-kyc-designations/getDesignations`,
  insertDesignationUrl: `${serverLocation}/api/v1/customer-kyc-designations`,
  updateDesignationUrl: `${serverLocation}/api/v1/customer-kyc-designations/update`,
  customerKycDesignationUrlUnauth: `${serverLocation}/api/customerKycDesignation/getDesignations`,
  insertDesignationUrlUnauth: `${serverLocation}/api/customerKycDesignation`,

  //Customer Kyc Credit Negotiation
  customerKycNegotiation: `${serverLocation}/api/v1/customer-kyc-negotiations`,
  selectByKeyCustomerKycNegotiation: `${serverLocation}/api/v1/customer-kyc-negotiations`,
  getCustomerKycNegotiationList: `${serverLocation}/api/v1/customer-kyc-negotiations/get-negotiations-list`,
  selectByKeyCustomerKycNegotiationApproveList: `${serverLocation}/api/v1/customer-kyc-negotiations/showApproveList`,
  renegotiation: `${serverLocation}/api/v1/customer-kyc-approve-log/renegotiation`,

  //Company Category
  categoryUrl: `${serverLocation}/api/v1/categories`,
  categoryBelowCompanyHierarchyUrl: `${serverLocation}/api/v1/categories/by-hierarchy`,
  customerKycUrl: `${serverLocation}/api/v1/customer-kycs`,
  getLoggedInUserCustomerKycUrl: `${serverLocation}/api/v1/customer-kycs/getLoggedInUserCompany`,

  // Customer Care Service
  customerCareServiceFormUrl: `${serverLocation}/api/v1/customer-care-services`,
  customerCareServiceFormUrlUpdated: `${serverLocation}/api/v1/customer-care-services/getAll`,
  getLoggedInUserInfoUrl: `${serverLocation}/api/v1/customer-care-services/getLoggedInUserInfo`,

  //Department roles or roles
  roleUrl: `${serverLocation}/api/v1/roles`,
  roleUrlPost: `${serverLocation}/api/v1/roles/getAllRoles`,
  updateRoleUrl: `${serverLocation}/api/v1/roles/update`,
  roleByDepartmentUrl: `${serverLocation}/api/v1/roles/getByDepartment`,
  deleteMultipleDepartmentRoles: `${serverLocation}/api/v1/roles/deleteMultipleDepartmentRoles`,
  getPermissionByUser: `${serverLocation}/api/v1/roles/getPermissionByRoles`,

  //Department Role has permission
  roleHasPermissionUrl: `${serverLocation}/api/v1/role-has-permissions`,
  permissionByRoleUrl: `${serverLocation}/api/v1/role-has-permissions/getPermissionsByRole`,
  searchPermissions: `${serverLocation}/api/v1/role-has-permissions/searchPermissions`,
  deleteMultiplePermissions: `${serverLocation}/api/v1/role-has-permissions/deleteMultiple`,
  //temp use
  selectCities: `${serverLocation}/api/v1/cities`,
  insertCity: `${serverLocation}/api/v1/cities`,
  searchByStartAlphabets: `${serverLocation}/api/v1/cities/searchByFirstAlphabet`,

  //terms and conditions
  termAndConditionUrl: `${serverLocation}/api/v1/terms-and-conditions`,
  updateTermAndConditionUrl: `${serverLocation}/api/v1/terms-and-conditions/update`,
  loggedInUserTermsAndConditions: `${serverLocation}/api/v1/terms-and-conditions/getByLoggedInUser`,
  acceptSystemTermsAndC: `${serverLocation}/api/v1/terms-and-conditions/acceptSystemTermsAndC`,
  getSystemTermsAndC: `${serverLocation}/api/v1/terms-and-conditions/get-system/terms-and-conditions`,
  // tERMS AND coNDITION  FOR DIFFERENT language
  termsAndConditionLanguage: `${serverLocation}/api/v1/terms-and-conditions/store-term-and-condition-multi-language`,

  //Module
  selectModules: `${serverLocation}/api/v1/modules`,
  updateModules: `${serverLocation}/api/v1/modules/update`,
  searchModules: `${serverLocation}/api/v1/modules/searchModule`,
  getModuleByURL: `${serverLocation}/api/v1/modules/getModuleByURL`,

  insertModule: `${serverLocation}/module/insert`,
  updateModule: `${serverLocation}/module/update`,
  deleteModule: `${serverLocation}/module/delete`,
  selectModuleByKey: `${serverLocation}/module/selectByKey`,
  deleteSelectedModule: `${serverLocation}/module/deleteSelected`,
  selectUnassignedModules: `${serverLocation}/module/selectUnassignedModules`,
  getModuleByName: `${serverLocation}/module/getModuleByName`,

  //Customer Tiers
  customerTiersUrl: `${serverLocation}/api/v1/tiers`,
  updateCustomerTiersUrl: `${serverLocation}/api/v1/tiers/update`,
  getAllTiers: `${serverLocation}/api/v1/tiers/getAllTiers`,
  searchTierUrl: `${serverLocation}/api/v1/tiers/searchTier`,
  getDefaultTiers: `${serverLocation}/api/v1/tiers/defaultTier`,
  setDefaultTier: `${serverLocation}/api/v1/tiers/setDefaultTier`,
  deleteCustomerTiersUrl: `${serverLocation}/api/v1/tiers/deleteMultipleTiers`,
  storeMarginCreditUrl: `${serverLocation}/api/v1/tiers/credit/storeMarginCredit`,
  getMarginCreditUrl: `${serverLocation}/api/v1/tiers/credit/getAllMarginCredit`,

  getSpecificMarginCustomerKyc: `${serverLocation}/api/v1/tiers/credit/getCustomerKycMargin`,

  // selectTiers: `${serverLocation}/tier/`,
  // insertTier: `${serverLocation}/tier/insert`,
  // setDefaultTier: `${serverLocation}/tier/setDefault`,
  // updateTier: `${serverLocation}/tier/update`,
  // deleteTier: `${serverLocation}/tier/delete`,
  // deleteSelectedTiers: `${serverLocation}/tier/deleteSelected`,
  // selectTierByKey: `${serverLocation}/tier/selectByKey`,

  assignTierToCustomer: `${serverLocation}/api/v1/customer-kyc-tiers/insert`,
  updateTierToCustomer: `${serverLocation}/customer-kyc-tiers/update`,

  //My network
  customerKycMyNetwork: `${serverLocation}/api/v1/customer-kycs/myNetwork`,
  searchMyNetwork: `${serverLocation}/myNetwork/search`,

  selectActivityType: `${serverLocation}/activityType/`,

  insertActivity: `${serverLocation}/activity/insert`,
  selectActivities: `${serverLocation}/activity/`,
  deleteActivity: `${serverLocation}/activity/delete`,
  updateActivity: `${serverLocation}/activity/update`,
  selectActivityByKey: `${serverLocation}/activity/selectByKey`,
  selectActivityTypeByKey: `${serverLocation}/activityType/selectByKey`,
  selectUnassignedActivities: `${serverLocation}/activity/selectUnassignedActivities`,

  // insertDepartment: `${serverLocation}/department/insert`,
  // selectDepartments: `${serverLocation}/department/`,
  // updateDepartment: `${serverLocation}/department/update`,
  // deleteDepartment: `${serverLocation}/department/delete`,
  // selectDepartmentByKey: `${serverLocation}/department/selectByKey`,
  // deleteSelectedDepartment: `${serverLocation}/department/deleteSelected`,

  insertDepartmentRole: `${serverLocation}/departmentRole/insert`,
  selectDepartmentRoles: `${serverLocation}/departmentRole/`,
  updateDepartmentRole: `${serverLocation}/departmentRole/update`,
  deleteDepartmentRole: `${serverLocation}/departmentRole/delete`,
  deleteSelectedDepartmentRole: `${serverLocation}/departmentRole/deleteSelected`,
  selectDepartmentRoleByKey: `${serverLocation}/departmentRole/selectByKey`,

  deleteSelectedActivity: `${serverLocation}/activity/deleteSelected`,
  deleteSelectedCity: `${serverLocation}/city/deleteSelected`,
  selectDepartmentRoleByDepartment: `${serverLocation}/departmentRole/`,

  // department role staff.
  departmentStaffUrl: `${serverLocation}/api/v1/staffs/getAllStaffs`,
  insertdepartmentStaff: `${serverLocation}/api/v1/staffs`,
  getDepartmentStaff: `${serverLocation}/api/v1/staffs`,
  departmentStaffUpdate: `${serverLocation}/api/v1/staffs/update`,
  departmentStaffSearch: `${serverLocation}/api/v1/staffs/departmentStaffSearch`,
  checkUserExits: `${serverLocation}/api/v1/staffs/checkUserExits`,
  selectDepartmentRoleStaff: `${serverLocation}/departmentRoleStaff/selectById`,
  deleteDepartmentRoleStaff: `${serverLocation}/departmentRoleStaff/delete`,
  deleteSelectedDepartmentRoleStaff: `${serverLocation}/departmentRoleStaff/deleteSelected`,
  deparmentStaffPermissions: `${serverLocation}/api/v1/roles/getAllDepartmentWithRoles`,
  getStaffDataById: `${serverLocation}/api/v1/staffs/getStaffDataById`,
  getSalesStaffs: `${serverLocation}/api/v1/staffs/get-staff/sales-visit-permission`,
  getPricingStaffs: `${serverLocation}/api/v1/staffs/get-users/pricing-permission`,

  // staff. part of department staff.
  selectStaffs: `${serverLocation}/staff/`,
  deleteStaff: `${serverLocation}/staff/delete`,
  deleteSelectedStaff: `${serverLocation}/staff/deleteSelected`,
  deleteMulitpleStaffs: `${serverLocation}/api/v1/staffs/deleteMultipleStaffs`,
  selectStaffByKey: `${serverLocation}/staff/selectByKey`,

  //rate enquiries
  rateEnquiryUrl: `${serverLocation}/rateEnquiry/`,
  selectRateEnquiryByKey: `${serverLocation}/rateEnquiry/selectByKey`,
  insertRateEnquiryUrl: `${serverLocation}/api/v1/rate-enquirys`,
  getRateEnquiryList: `${serverLocation}/api/v1/rate-enquirys/getList`,
  rateEnquiryQueryList: `${serverLocation}/api/v1/rate-enquirys/rateEnquiryList`,
  rateEnquiryListForCustomer: `${serverLocation}/api/v1/rate-enquirys/showCustomer`,
  getMyRateEnquiryNetwork: `${serverLocation}/api/v1/rate-enquirys/myNetwork`,

  getRateEnquiriesForAccounts: `${serverLocation}/api/v1/rate-enquirys/getAccountEnquiries`,
  getRateEnquiriesForAccountsVendor: `${serverLocation}/api/v1/rate-enquirys/getVendorAccountEnquiries`,
  getAccountEnquiriesDetails: `${serverLocation}/api/v1/rate-enquirys/getAccountEnquiriesDetails`,

  //getCustomCahrges
  getRateEnqCustomCharges: `${serverLocation}/api/v1/rate-enquiry-freight-charges/get-custom-charges`,

  getCustomerEnquiries: `${serverLocation}/api/v1/rate-enquirys/getCustomerEnquiries`,
  getPriceSentAndReceived: `${serverLocation}/api/v1/rate-enquirys/getPriceSentAndReceived`,
  getCompanyOperationEnquiries: `${serverLocation}/api/v1/rate-enquirys/getCompanyOperationEnquiries`,
  checkDoUploadable: `${serverLocation}/api/v1/rate-enquirys/checkDoUploadable`,
  getVendorOperationEnquiries: `${serverLocation}/api/v1/rate-enquirys/getVendorOperationEnquiries`,
  updateRateEnquiryStatus: `${serverLocation}/api/v1/rate-enquirys/updateJobStatus`,
  getOperationThreshold: `${serverLocation}/api/v1/rate-enquirys/operation-threshold`,
  getDashboardEnquiries: `${serverLocation}/api/v1/rate-enquirys/dashboardEnquiries`,
  getDashboardStats: `${serverLocation}/api/v1/rate-enquirys/dashboardStats`,
  uploadRateEnquiryFiles: `${serverLocation}/api/v1/rate-enquirys/uploadRateEnquiryFiles`,
  deleteRateEnquiryFiles: `${serverLocation}/api/v1/rate-enquirys/deleteRateEnquiryFiles`,
  approveBL: `${serverLocation}/api/v1/rate-enquirys/approveBL`,
  statusUpdateRequest: `${serverLocation}/api/v1/rate-enquirys/statusUpdateRequest`,
  cancelRateEnquiry: `${serverLocation}/api/v1/rate-enquirys/cancelRateEnquiry`,
  submitEnquiryFeedback: `${serverLocation}/api/v1/rate-enquiry-feedbacks/store`,
  getOperationEnquiry: `${serverLocation}/api/v1/rate-enquirys/get-rate-enquiry-details`,

  //rate enquiry feedback
  insertRateEnquiryFeedback: `${serverLocation}/api/insertRateEnquiryFeedback`,

  //rate enquiry supplier detail
  getRateEnquirySupplierDetailsById: `${serverLocation}/api/v1/rate-enquiry-supplier-details/getSupplierDetailsByCustomerKycId`,

  //track rate enquiry
  trackRateEnquiry: `${serverLocation}/api/v1/rate-enquirys/trackRateEnquiry`,

  //customer service modul
  // getCustomerEnquiriesForCustomerService: `${serverLocation}/api/v1/rate-enquirys/getCompanyEnquiriesForCustomerService`,
  // getVendorEnquiriesForCustomerService: `${serverLocation}/api/v1/rate-enquirys/getVendorEnquiriesForCustomerService`,
  getCustomerEnquiriesForCustomerService: `${serverLocation}/api/v1/rate-enquirys/getCompanyOperationEnquiries`,
  getVendorEnquiriesForCustomerService: `${serverLocation}/api/v1/rate-enquirys/getVendorOperationEnquiries`,

  //rate enquiry status
  insertRateEnquiryStatus: `${serverLocation}/api/v1/rate-enquiry-statuses`,
  getRateEnquiryStatus: `${serverLocation}/api/v1/rate-enquiry-statuses`,
  getOperationStatus: `${serverLocation}/api/v1/rate-enquiry-statuses/getOperationStatuses`,
  getOperationStatusByRateEnquiryId: `${serverLocation}/api/v1/rate-enquiry-statuses/getOperationStatusesByEnquiry`,
  getAccountStatus: `${serverLocation}/api/v1/rate-enquiry-statuses/getAccountStatuses`,
  storeCustomStatus: `${serverLocation}/api/v1/rate-enquiry-statuses/storeStatus`,
  requestStatusDelete: `${serverLocation}/api/v1/rate-enquiry-statuses/deleteRequested`,
  getStatusUpdateRequest: `${serverLocation}/api/v1/rate-enquiry-statuses/getRequestedDeleteStatusUpdates`,
  requestAmendment: `${serverLocation}/api/v1/rate-enquiry-statuses/request-for-amendment`,
  getAllRequestAmendment: `${serverLocation}/api/v1/rate-enquiry-statuses/get-all/amendment-request`,
  respondToAmendmentRequest: `${serverLocation}/api/v1/rate-enquiry-statuses/respond-to-amendment-request`,

  getRateQuery: `${serverLocation}/api/v1/role-has-permissions/getAuthUserRateWizardViewPermission`,
  getPricingQueries: `${serverLocation}/api/v1/role-has-permissions/getAuthUserPricingViewPermission`,

  releaseDO: `${serverLocation}/api/v1/rate-enquiry-statuses/releaseDO`,

  // insertRateEnquiryUrl: `${serverLocation}/rateEnquiry/insert`,
  updateRateEnquiryUrl: `${serverLocation}/api/v1/rate-enquirys/update`,
  deleteRateEnquiryUrl: `${serverLocation}/rateEnquiry/delete`,
  getTotalEnquiriesByStatus: `${serverLocation}/rateEnquiry/getTotalEnquiriesByStatus`,

  ffCustomerEnquiryUrl: `${serverLocation}/rateEnquiry/myCustomerEnquiry`,
  enquiriesToNwVendorUrl: `${serverLocation}/rateEnquiry/`,
  enquiriesByNwVendorUrl: `${serverLocation}/rateEnquiryForward/forwardedTo`,

  insertDepartmentRolePermission: `${serverLocation}/departmentRolePermission/insert`,
  selectDepartmentRolePermissions: `${serverLocation}/departmentRolePermission/`,
  deleteDepartmentRolePermission: `${serverLocation}/departmentRolePermission/delete`,
  deleteSelectedDepartmentRolePermission: `${serverLocation}/departmentRolePermission/deleteSelected`,
  getLoggedInCustomer: `${serverLocation}/customerKyc/getLoggedInCustomer`,
  getCategoryPermission: `${serverLocation}/api/v1/category-permissions`,

  // Incoterms. International Commerce Terms
  selectIncoterms: `${serverLocation}/incoterm/`,

  // Incoterms. International Commerce Terms
  insertInvoiceSettings: `${serverLocation}/api/v1/invoice-settings`,
  getInvoiceSetting: `${serverLocation}/api/v1/invoice-settings/myInvoiceSetting`,
  updateInvoiceSettings: `${serverLocation}/api/v1/invoice-settings/update/`,

  // Get Commodity Types
  getCommodityTypes: `${serverLocation}/api/v1/commodity-types`,

  selectContainerSizeCategories: `${serverLocation}/containerSizeCategory/`,

  //Ports
  portsUrl: `${serverLocation}/api/v1/ports`,
  portsUpdateUrl: `${serverLocation}/api/v1/ports/update`,
  selectPortByTypeAndCountry: `${serverLocation}/api/v1/ports/getPortByTypeAndCountryCode`,
  getPortByTypeAndOriginCountryCode: `${serverLocation}/api/v1/ports/getPortByTypeAndOriginCountryCode`,
  // custom points
  getCustomPointByTypeAndOriginCountryCode: `${serverLocation}/api/v1/ports/getCustomPointByTypeAndOriginCountryCode`,
  getCustomPointByTypeAndDestinationCountryCode: `${serverLocation}/api/v1/ports/getCustomPointByTypeAndDestinationCountryCode`,

  selectPortByTypeCountryAndCityZipcode: `${serverLocation}/api/v1/ports/getPortByTypeAndCountryCodeAndCityZipCode`,
  searchPorts: `${serverLocation}/api/v1/ports/searchPorts`,
  deleteMultiplePorts: `${serverLocation}/api/v1/ports/deleteMultiple`,
  getGlobalPorts: `${serverLocation}/api/v1/ports/globalPorts`,
  getPortList: `${serverLocation}/api/v1/ports/getPortList`,

  getPaginatedPort: `${serverLocation}/api/v1/ports/allPaginated`,
  getPortListByFreightType: `${serverLocation}/api/v1/ports/getPortListByFreightType`,

  //rate enquiry forward
  forwardRateEnquiry: `${serverLocation}/api/v1/rate-enquiry-forwards`,
  updateRateEnquiryFwdStatus: `${serverLocation}/api/v1/rate-enquiry-forwards/updateStatus`,
  updateAcceptRateEnquiryFwdStatus: `${serverLocation}/api/v1/rate-enquiry-forwards/updateAcceptStatus`,
  updateAcceptRateEnquiryBooking: `${serverLocation}/api/rate-enquiry-forwards/updateAcceptStatus`,

  getGroupRefNo: `${serverLocation}/api/v1/rate-enquiry-forwards`,
  getForwardedCompanyListByRefNo: `${serverLocation}/api/v1/rate-enquiry-forwards/getForwardedCompanyListByRefNo`,
  getManualForwardedVendors: `${serverLocation}/api/v1/rate-enquiry-forwards/manualForwardedVendors`,

  //manual rate enquiry forward
  manualForwardRateEnquiryOuterLink: `${serverLocation}/api/manual-forwards`,
  manualForwardRateEnquiry: `${serverLocation}/api/v1/manual-forwards`,
  getResponsePassword: `${serverLocation}/api/getResponsePassword`,
  vendorForwardRateEnquiryOuterLink: `${serverLocation}/api/vendor-forwards`,

  //freight charges
  insertFreightCharges: `${serverLocation}/api/v1/rate-enquiry-freight-charges`,
  updateFreightCharges: `${serverLocation}/api/v1/rate-enquiry-freight-charges/update`,
  getFreightChargesByRefNo: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getByForwardsRefNo`,
  getByForwardsGroupRefNo: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getByForwardsGroupRefNo`,
  getByForwardsAllRef: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getByForwardsAllRef`,
  insertCustomCharges: `${serverLocation}/api/v1/rate-enquiry-freight-charges/custom-charges`,

  // getChargesByResponse: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getChargesByResponse`,
  getByForwardsGroupRefNoOuterLink: `${serverLocation}/api/freight-charge-getByForwardsGroupRefNo-outer-link`,
  getChargesByResponse: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getChargesByResponse`,
  getChargesByResponseBooking: `${serverLocation}/api/rate-enquiry-freight-charges/getChargesByResponseBooking`,

  getChargesByResponseFFD: `${serverLocation}/api/v1/rate-enquiry-freight-charges/getChargesByResponseFFD`,

  //manual freight charges
  insertManualFreightCharges: `${serverLocation}/api/v1/manual-forward-responses`,
  insertManualFreightChargesOuterLink: `${serverLocation}/api/manual-forward-responses-outerlink`,
  insertVendorFreightChargesOuterLink: `${serverLocation}/api/vendor-forward-responses-outerlink`,

  getManualFreightChargesByRefNo: `${serverLocation}/api/v1/manual-forward-responses/getByForwardRefNo`,
  getOuterLinkManualFreightChargesByRefNo: `${serverLocation}/api/manual-forward-responses/getByForwardRefNo`,
  getOuterLinkVendorFreightChargesByRefNo: `${serverLocation}/api/vendor-forward-responses/getByForwardRefNo`,

  //rate negotiation
  rateNegotiation: `${serverLocation}/api/v1/rate-negotiations`,
  updaterateNegotiationStatus: `${serverLocation}/api/v1/rate-negotiations/updateStatus`,
  getListByUserIdRateNegotiation: `${serverLocation}/api/v1/rate-negotiations/getListByUserId/`,

  //My Profile
  getMyProfile: `${serverLocation}/api/v1/getMyProfileInfo`,

  //getDG class
  dgClasses: `${serverLocation}/api/v1/dg-classes`,

  //summary
  customerDashboardInfo: `${serverLocation}/api/v1/summaries/customerDashboard`,
  rateWizardQuickSummary: `${serverLocation}/api/v1/summaries/rateWizardQuickSummary`,
  getQuickSummaryEnquiries: `${serverLocation}/api/v1/summaries/getQuickSummaryEnquiries`,
  customerEnquiriesQuickSummary: `${serverLocation}/api/v1/summaries/customerEnquiriesQuickSummary`,
  getOperationsQuickSummary: `${serverLocation}/api/v1/summaries/getOperationsQuickSummary`,
  getCustomerServiceQuickSummary: `${serverLocation}/api/v1/summaries/getCustomerServiceQuickSummary`,
  getDocumentationQuickSummary: `${serverLocation}/api/v1/summaries/getDocumentationQuickSummary`,
  getAccountQuickSummary: `${serverLocation}/api/v1/summaries/getAccountQuickSummary`,

  // send custom email
  sendCustomEmail: `${serverLocation}/api/v1/send-custom-email`,

  //vendor
  getAllVendors: `${serverLocation}/api/v1/vendors`,
  registorVendorUrl: `${serverLocation}/api/v1/vendors/registerVendor`,
  searchVendor: `${serverLocation}/api/v1/vendors/searchVendor`,
  updateVendor: `${serverLocation}/api/v1/vendors/updateVendor`,
  getOwnVendors: `${serverLocation}/api/v1/vendors/get-own-vendors`,
  vendorToFfdRequest: `${serverLocation}/api/v1/vendors/requestConvertIntoFfd`,
  getVendorToFfdConvertRequest: `${serverLocation}/api/v1/vendors/get/vendorToFfdConvertRequest`,
  acceptConvertRequest: `${serverLocation}/api/v1/vendors/approveConvertIntoFfd`,
  rejectConvertRequest: `${serverLocation}/api/v1/vendors/rejectConvertIntoFfd`,
  getVendorCreators: `${serverLocation}/api/v1/vendors/get-creator-users/of-vendors`,
  //Operations status history
  getRateEnquiryStatusHistory: `${serverLocation}/api/v1/rate-enquiry-statuses/statusHistory`,
  getRateEnquiryStatusHistoryBooking: `${serverLocation}/api/rate-enquiry-statuses/statusHistory`,

  // Get Rate Enquiry Supplier Details
  getRateEnquirySupplierDetails: `${serverLocation}/api/v1/rate-enquiry-supplier-details/getByRateEnquiry`,

  //Shipping Company
  getAllShippingCompanies: `${serverLocation}/api/shipping-company`,

  //Company Settings
  getTimeZones: `${serverLocation}/api/v1/time-zones`,
  getWeekDays: `${serverLocation}/api/v1/week-days`,
  insertWorkTimeSetting: `${serverLocation}/api/v1/work-time-setting`,
  insertNotificationSetting: `${serverLocation}/api/v1/notification-settings`,
  updateNotificationSetting: `${serverLocation}/api/v1/notification-settings/update`,
  insertOperationCostSetting: `${serverLocation}/api/v1/company_default_operating_cost_setting`,
  // getCompanySettings: `${serverLocation}/api/v1/work-time-setting`,
  getOperationCostSetting: `${serverLocation}/api/v1/company_default_operating_cost_setting`,
  updateOperationCostSetting: `${serverLocation}/api/v1/company_default_operating_cost_setting/update`,

  //Vendor Attending Log
  isAttending: `${serverLocation}/api/v1/vendor_attend_logs`,
  insertVendorAttendLog: `${serverLocation}/api/v1/vendor_attend_logs`,
  assignVendorAttendLog: `${serverLocation}/api/v1/vendor_attend_logs/assign`,
  updateStatusVendorAttendLog: `${serverLocation}/api/v1/vendor_attend_logs/updateStatus`,

  getDepartmentStaffByPermission: `${serverLocation}/api/v1/department-staffs/getDepartmentStaffByPermission`,
  getPricingPersons: `${serverLocation}/api/v1/department-staffs/getPricingPersons`,

  enableDisableStaff: `${serverLocation}/api/v1/department-staffs/enableDisableStaff`,

  // Vendor Complains
  insertVendorComplain: `${serverLocation}/api/v1/vendor_complains`,

  // reponses
  getResponses: `${serverLocation}/api/v1/responses`,

  //sales-visit
  getSalesVisitData: `${serverLocation}/api/v1/sales-visits/getAllUpdated`,
  insertSalesVisit: `${serverLocation}/api/v1/sales-visits`,
  updateSalesVisit: `${serverLocation}/api/v1/sales-visits/update`,
  exportDataToExcel: `${serverLocation}/api/v1/sales-visits/exportData`,
  exportPeriodDataToExcel: `${serverLocation}/api/v1/sales-visits/exportPeriodData`,
  getSalesVisitFeedBack: `${serverLocation}/api/v1/sales-visits`,
  getSalesVisitType: `${serverLocation}/api/v1/sales-visits/activityTypeGet`,
  fetchSalesVisitCompanyDetail: `${serverLocation}/api/v1/sales-visits/getByName`,
  fetchSalesVisitReferralDetail: `${serverLocation}/api/v1/sales-visits/getAllReferredUser`,
  salesActivityUpdate: `${serverLocation}/api/v1/sales-visits/activityTypeUpdate`,

  //company-default-required-files
  getRequiredFilesByLoggedIn: `${serverLocation}/api/v1/company-default-required-files/getByLoggedUser`,
  getRequiredFilesNew: `${serverLocation}/api/v1/customer-kycs/company-operation-file-setting/get`,
  insertRequiredFiles: `${serverLocation}/api/v1/company-default-required-files`,
  deleteRequiredFile: `${serverLocation}/api/v1/company-default-required-files/destroy`,

  //company operating cost incoterm
  insertCompanyOperatingCostIncoterm: `${serverLocation}/api/v1/company-operating-cost-incoterm`,
  getCompanyOperatingCostIncoterm: `${serverLocation}/api/v1/company-operating-cost-incoterm/getByLoggedUser`,

  //company disclaimer
  getCompanyDisclaimer: `${serverLocation}/api/v1/customerkyc-disclaimer`,
  insertCompanyDisclaimer: `${serverLocation}/api/v1/customerkyc-disclaimer`,
  makeActiveCompanyDisclaimer: `${serverLocation}/api/v1/customerkyc-disclaimer/make-active`,

  //new booking

  insertNewBooking: `${serverLocation}/api/v1/rate-enquirys/generateNewBooking`,

  // Customer KYC User Invitations

  getUserInvitation: `${serverLocation}/api/v1/invitations/getUserInvitation`,
  getCompanyInvitation: `${serverLocation}/api/v1/invitations/getCompanyInvitation`,
  getUserInvitation: `${serverLocation}/api/v1/invitations/getUserInvitation`,

  resendInvitation: `${serverLocation}/api/v1/registerLinkToCustomer`,

  // DO settings
  insertDOSettings: `${serverLocation}/api/v1/do-settings`,
  getDOSetting: `${serverLocation}/api/v1/do-settings/myDOSetting`,

  //get list of customers
  getCustomerList: `${serverLocation}/api/v1/sales/get-customers`,

  //old jobs
  storeOldJobs: `${serverLocation}/api/v1/old-jobs`,

  //RateEnquiry File Types
  getRateEnquiryFileTypes: `${serverLocation}/api/v1/rate-enquirys/getRateEnquiryFileTypes`,

  storeRateEnquiryAttend: `${serverLocation}/api/v1/rate-enquirys/attend-rate-enquiry`,

  //booking information
  insertBookingInfo: `${serverLocation}/api/v1/booking-information`,
  cancellBooking: `${serverLocation}/api/v1/booking-information/cancel`,

  //public Channel Discussion
  publicChannelDiscussion: `${serverLocation}/api/v1/public-channels`,
  readMessage: `${serverLocation}/api/v1/public-channels/read-message`,
  getUnreadMessage: `${serverLocation}/api/v1/public-channels/get-unread-message`,
  publicChannelDiscussionPaginated: `${serverLocation}/api/v1/public-channels/getPaginated`,

  // currencies
  currency: `${serverLocation}/api/v1/currencies`,
  currencyNoAuth: `${serverLocation}/api/currencies`,

  // invoices
  sendInvoice: `${serverLocation}/api/v1/rate-enquirys/accounts/send-invoice`,
  getInvoice: `${serverLocation}/api/v1/rate-enquirys/accounts/get-invoice`,

  // bank details
  companyBankDetails: `${serverLocation}/api/v1/bank-details`,
  getBankDetails: `${serverLocation}/api/v1/bank-details/getBankDetails`,
  deleteCompanyBankDetail: `${serverLocation}/api/v1/bank-details/delete`,
  updateCompanyBankDetail: `${serverLocation}/api/v1/bank-details/update`,
  setPrimaryCompanyBankDetail: `${serverLocation}/api/v1/bank-details/set-as-primary`,

  //company-pagination
  companyPagination: `${serverLocation}/api/v1/company-pagination-setting`,

  //dashboard stats
  getAdminDashboardStats: `${serverLocation}/api/v1/admin-dashboard-stats/stats`,
  //updateRateEnquiryLog
  updateRateEnquiryLog: `${serverLocation}/api/v1/rate-enquiry-log/updateRateEnquiryLog`,

  //insert Rate Details
  insertRateDetail: `${serverLocation}/api/v1/valid-rate/store`,
  getRateDetail: `${serverLocation}/api/v1/rate-list/getList`,
  updateRateDetail: `${serverLocation}/api/v1/rate-list/update`,
  // deleteRateDetail: `${serverLocation}/api/v1/rate-list/delete`,

  //imported Details
  getImportedRateList: `${serverLocation}/api/v1/valid-rate/getImportedRateList`,
  getWeightSlabData: `${serverLocation}/api/v1/valid-rate/getWeightSlabData`,
  uploadValidRate: `${serverLocation}/api/v1/valid-rate/import`,
  getWeightSlabData: `${serverLocation}/api/v1/valid-rate/getWeightSlabData`,
  deleteRateList: `${serverLocation}/api/v1/valid-rate/delete`,
  updateRateList: `${serverLocation}/api/v1/valid-rate/update`,

  //getVendorsBySearch
  getVendorsBySearch: `${serverLocation}/api/v1/vendors/getVendorsBySearch`,
  //fetchPotentialCustomer
  fetchPotentialCustomer: `${serverLocation}/api/v1/potential-customer`,
  fetchPotentialCustomerByName: `${serverLocation}/api/v1/potential-customer/get`,
  //currencySetting
  currencySetting: `${serverLocation}/api/v1/currency-setting`,

  //ffd registration
  registerFFD: `${serverLocation}/api/v1/ffd-registration`,

  //automate valid rate setting
  storeAutomateSetting: `${serverLocation}/api/v1/automate-valid-rate`,
  // follow ups
  addToFollowUp: `${serverLocation}/api/v1/follow-ups`,
  getFollowUpStatus: `${serverLocation}/api/v1/follow-ups/get-status`,

  //booking form
  getBookingDetails: `${serverLocation}/api/booking-details`,
  createBooking: `${serverLocation}/api/v1/booking-form/store`,

  //sms-marketing
  getSmsMarketing: `${serverLocation}/api/v1/rate-enquirys/get-customers-based-on-queries`,
  //email-sms-setting
  getNotificationSettingByCompany: `${serverLocation}/api/v1/email-sms-setting/getByCompany`,
  getOwnCompany: `${serverLocation}/api/v1/email-sms-setting/getOwnCompany`,
  insertNotificationRecords: `${serverLocation}/api/v1/email-sms-setting/insertRecords`,
  updateSMSSetting: `${serverLocation}/api/v1/email-sms-setting/update`,

  //weight slab container setting
  getWeightSlabContainerData: `${serverLocation}/api/v1/container-size-weight-slab`,
  storeWeightSlabContainerData: `${serverLocation}/api/v1/container-size-weight-slab/store`,
  updateWeightSlabContainerData: `${serverLocation}/api/v1/container-size-weight-slab/update`,
  deleteWeightSlabContainerData: `${serverLocation}/api/v1/container-size-weight-slab/delete`,

  //potential customer sms setting
  storePotentialCustomerSetting: `${serverLocation}/api/v1/email-sms-setting/potential-cus/store`,
  getPotentialCustomerSetting: `${serverLocation}/api/v1/email-sms-setting/getPotentialCustomerSetting`,
  //NotificationSetting
  getNotificationsetting: `${serverLocation}/api/v1/email-sms-setting/getByCompany`,
  insertEmailAndNumber: `${serverLocation}/api/v1/email-sms-setting/insertRecords`,

  //sms logs
  getSmsLogs: `${serverLocation}/api/v1/sms-logs/getLogs`,
  getSmslogsDetails: `${serverLocation}/api/v1/sms-logs/detail`,
  getAllSmsView: `${serverLocation}/api/v1/sms-logs/rate-enquiry/sms-logs/`,
  createAllSave: `${serverLocation}/api/v1/email-sms-setting/insert-bluk-records`,
  WeeklyReportEmail: `${serverLocation}/api/v1/email-sms-setting/weekly-report-send-or-not`,
  SendAdminMail: `${serverLocation}/api/v1/email-sms-setting/admin-mail-send-or-not`,

  //import valid rates

  downlaodSampleFile: `${serverLocation}/download-sample-file-export-rates`,
  //news and updates

  getAllNews: `${serverLocation}/api/v1/news`,
  insertNews: `${serverLocation}/api/v1/news/insert`,
  updateNews: `${serverLocation}/api/v1/news/update`,
  deleteNews: `${serverLocation}/api/v1/news/delete`,

  //booking Form Link Update
  updateBookingLink: `${serverLocation}/api/v1/rate-enquirys/renew-booking-link`,

  //Rate Date Update
  rateDateUpdate: `${serverLocation}/api/v1/rate-enquiry-freight-charges/updateValidDate`,

  //getPackageLists
  getPackageList: `${serverLocation}/api/v1/package-types`,

  //get customer transfer list
  getCustomerTransferList: `${serverLocation}/api/v1/customer-kycs/getAllowedUsers`,

  //update customer transfer list
  updateCustomerTransferStatus: `${serverLocation}/api/v1/customer-kycs/transferCustomer`,

  //valid rate
  getPortLists: `${serverLocation}/api/v1/ports/getPortListTypeWise`,
  addLocalCharge: `${serverLocation}/api/v1/valid-rate/port-local-charge`,
  getLocalChargeData: `${serverLocation}/api/v1/valid-rate/getAllPortLocalCharge`,
  getCustomLocalChargeRate: `${serverLocation}/api/v1/valid-rate/populateDataPortWise`,

  // leadSettingsAPis
  getLeadSettings: `${serverLocation}/api/v1/lead-setting/get-sales-person`,
  getAllLeadsData: `${serverLocation}/api/v1/lead-setting/getAll`,
  storeLeadsData: `${serverLocation}/api/v1/lead-setting`,
  updateLeadsData: `${serverLocation}/api/v1/lead-setting/update`,
  DeleteLeadsData: `${serverLocation}/api/v1/lead-setting/delete`,
  
  // salesReport
  getSalesReport: `${serverLocation}/api/v1/sales-visits/getSalesReport`,
  getSalesReportSalesUsers: `${serverLocation}/api/v1/sales-visits/get-sales-user`,
};

export const headers = {
  Authorization: `Bearer ${localStorage.getItem('token')} `,
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'Client-Ip': localStorage.getItem('my_ip')
};

export const INCOTERM_CODE = {
  EXWORK: 'EXW',
  CIF: 'CIF',
  FCA: 'FCA',
  FOB: 'FOB',
  INLAND_HAULAGE: 'INL'
};

export const COMMODITY_TYPE_CODE = {
  GENERAL: 'GNRL',
  HAZARDOUS: 'HZMT',
  TEMPERATURE_CONTROLLED: 'TEMP'
};

export const CONTAINER_SIZE_CODE = {
  REGULAR_SIZED: 'RGS',
  IN_GAUGE: 'ING',
  OUT_GAUZE: 'OTG',
  REEFER_EQUIPMENT: 'FRZ', //FROZEN OR FREEZER
  OPEN_TOP: 'OTP'
};

export const COLLECTION_NAME = {
  companyLogo: 'CL',
  companyRegistrationDocument: 'CRD',
  bankDetailDocument: 'BDD',
  taxDocument: 'TD',
  additionalDocument: 'additional_documents',
  WHOLE_SET_DOCUMENT: 'WSET',
  DO: 'DO',
  COST_SHEET: 'CSHT',
  SHIPPING_BILL: 'SBIL',
  FREIGHT_CERTIFICATE: 'FC',
  DEBIT_NOTE: 'DN',
  CREDIT_NOTE: 'CN',
  COMMERCIAL_INVOICE: 'CI',
  PORFORMA_INVOICE: 'PI',
  LETTER_OF_CREDIT_TELEGRAPHIC_TRANSFER: 'LC/TT',
  PACKING_LIST: 'PL',
  PURCHASE_ORDER: 'PO',
  HBL: 'HBL',
  MBL: 'MBL'
};

export const ADDITIONALFILES = [
  { label: 'COST SHEET', value: 'CSHT', permission: 'upload-cost-sheet' },
  { label: 'SHIPPING BILL', value: 'SBIL', permission: 'upload-shipping-bill' },
  { label: 'FREIGHT CERTIFICATE', value: 'FC', permission: 'upload-freight-certificate' },
  { label: 'DEBIT NOTE', value: 'DN', permission: 'upload-debit-note' },
  { label: 'CREDIT NOTE', value: 'CN', permission: 'upload-credit-note' },
  { label: 'PROFORMA INVOICE', value: 'PI', permission: 'upload-proforma-invoice' },
  { label: 'COMMERCIAL INVOICE', value: 'CI', permission: 'upload-commercial-invoice' },
  { label: 'PACAKGING LIST', value: 'PL', permission: 'upload-packaging-list' },
  { label: 'PURCHASE ORDER', value: 'PO', permission: 'upload-purchase-order' },
  { label: 'LC/TT', value: 'LC/TT', permission: 'upload-lc-tt' },
  { label: 'OTHER', value: 'OTHER', permission: false }
];

export const REACT_APP_WEBSOCKETS_KEY = 'local';
export const REACT_APP_WEBSOCKETS_SERVER = '127.0.0.1';

export const statuses = {
  accepted: 'Accepted',
  booked: 'Booked',
  received: 'Received',
  selected: 'Selected',
  rejected: 'Rejected',
  negotiating: 'Negotiating'
};

export const JOBSTATUS = {
  QUEUED: 'QUED',
  QUERY: 'QERY',
  REJECTED: 'QREJ'
};

export const MODULES = {
  pricing: 'Pricing'
};

export const categoryCodes = {
  FFD: 'CAT_FFD',
  ADMIN: 'CAT_ADM',
  CUSTOMER: 'CAT_CUS'
};

export const FORWARDEDSTATUSES = {
  already_forwarded: 'Already Forwarded',
  received: 'Received'
};

export const companyRole = () => {
  const ckyc = JSON.parse(localStorage.getItem('customerKyc'));
  if (ckyc) {
    return ckyc?.companyCategory?.categoryCode;
  } else {
    return '';
  }
};

export const getCustomerData = () => {
  const ckyc = JSON.parse(localStorage.getItem('customerKyc'));
  return ckyc;
};

export const jobStatusCodes = {
  QUEUED: 'QUED',
  SPACE_BOOKED: 'SPBO',
  SPACE_RELEASED: 'SPRE',
  DO_RELEASED: 'DORE',
  CARGO_PICKUP: 'CAPI',
  BL_APPROVED: 'BLAP',
  CARGO_LOADED: 'CGLD',
  CARGO_RELEASED: 'CGRE',
  ARRIVED_AT_DELIVERY_POINT: 'AADP',
  ARRIVED_AT_DELIVERY_PLACE: 'ADPL',
  PAYMENT_RELEASED: 'PREL',
  PAYMENT_RECIEVED: 'PREC',
  PAYMENT_RELEASED_VENDOR: 'PRTV',
  SERVICE_UNAVAILABLE: 'SEUN',
  IN_TRANSIT: 'TRNT',
  REJECTED: 'QREJ',
  CANNOT_PROCEED: 'CPRD',
  CANCELLED: 'CNCL',
  CLOSED: 'CLOS'
};

export const pricingPermission = [
  {
    displayName: 'View Sea Pricing Query',
    name: 'view-sea-query-pricing|Pricing'
  },
  {
    displayName: 'View Air Pricing Query',
    name: 'view-air-query-pricing|Pricing'
  },
  {
    displayName: 'View Both Query',
    name: 'both|Pricing'
  }
];

export const customerEnquiriesPermission = [
  {
    displayName: 'View Customer Enquiries',
    name: 'view-customer-enquiries|Customer Enquiries'
  }
];

export const viewSmsNumber = {
  SALES: 'view-customer-query-sales|Customer Enquiries',
  CUSTOMER_SERVICE: 'view-customer-enquiries|Customer Service',
  ACCOUNTS: 'view-customer-enquiries-account|Accounts'
};

export const defaultPageSize = 10;

export const incoTermsArray = [
  {
    code: 'EXW',
    name: 'Ex Works',
    displayName: 'Ex Works',
    displayOrder: 1,
    description:
      "This term places the maximum obligation on the buyer and minimum obligations on the seller. The Ex Works term is often used when making an initial quotation for the sale of goods without any costs included. EXW means that a buyer incurs the risks for bringing the goods to their final destination. Including charges only up to the seller's factory or premises. All charges from there on, such delivery, distribution, and commissions, are to be borne by the buyer."
  },
  {
    code: 'CIF',
    name: 'Cost, Insurance, and Freight ',
    displayName: 'CIF',
    displayOrder: 2,
    description:
      "Cost, Insurance, and Freight (CIF) is an expense paid by a seller to cover the costs, insurance, and freight against the possibility of loss or damage to a buyer's order while it is in transit to an export port named in the sales contract. Until the loading of the goods onto a transport ship is complete, the seller bears the costs of any loss or damage to the product. Further, if the product requires additional customs or export paperwork or requires inspections or rerouting, the seller must cover these expenses. Once the freight loads, the buyer becomes responsible for all other costs."
  },
  {
    code: 'FOB',
    name: 'Free on Board',
    displayName: 'FOB',
    displayOrder: 3,
    description:
      'Term of sale under which the price invoiced or quoted by the seller includes all charges up to placing the goods on board a ship at the port of departure specified by the buyer. Also called collect freight, freight collect, or freight forward.Used in shipping to indicate that there is no charge to the buyer for goods placed on board a carrier at the point of shipment. Typically followed by the name of a port or city, e.g., F.O.B. San Francisco.'
  },
  {
    code: 'FCA',
    name: 'Free Carrier',
    displayName: 'FCA',
    displayOrder: 4,
    description:
      'This term means that the seller delivers the goods, cleared for export, to the carrier nominated by the buyer at the named place. Seller pays for carriage to the named place.'
  },
  {
    code: 'INL',
    name: 'Inland Haulage',
    displayName: 'Inland Haulage',
    displayOrder: 5,
    description:
      'Inland Haulage means the transportation charges from inland container freight station to sea port of loading or vice versa. If Cargo freight station is away from sea port of loading, the shipper completes customs formalities at such container freight station and arranges to move cargo to port of loading either by rail or road. If moved by rail, the charges of moving goods from such location to port of loading or movement charges from port to inland freight station is known as Inland Haulage. It varies CFS to CFS, as the distance from CFS to port of loading varies one to another. It is collected by a shipping line when releasing bill of lading for export shipments, and when issuing a delivery order in case of import.'
  }
];

export const rateWizardViewPermissions = {
  own: 'view|Rate Wizard',
  all: 'view-my-company-query|Rate Wizard'
};

export const baAdminPermission = 'business-administration-super-user';

export const operationPermissions = {
  Air: 'view-air-query-operation|Operation Enquiries',
  Sea: 'view-sea-query-operation|Operation Enquiries'
};

export const IMPEXO_WEBSITE = 'https://allimpexo.com/';

//set for any default status that require to updated randomly any time, if not updated before
export const canUpdateRandomStatus = ['BLAP'];

export const incoTermsArraySelect = [
  {
    value: 'EXW',
    label: 'EXW'
  },
  {
    value: 'CIF',
    label: 'CIF'
  },
  {
    value: 'FOB',
    label: 'FOB'
  },
  {
    value: 'FCA',
    label: 'FCA'
  },
  {
    value: 'INL',
    label: 'INL'
  }
];
