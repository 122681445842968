import React, { Component, Suspense, lazy } from 'react';
import NotFound from './components/Auth/NotFound/NotFound';
import { Route, Switch, withRouter } from 'react-router-dom';
import { getModules, setSubModuleToggle } from './ducks/NavHeaderDucks';
import { connect } from 'react-redux';
import ErrorFallback from './components/ErrorBoundary/FallBack';
import { ErrorBoundary } from 'react-error-boundary';
import UpdatePassword from './components/Auth/UpdatePassword/UpdatePassword';
// import Notifications from "./components/Notification/Notification";
import PrivateRoute from './components/Auth/PrivateRoute/PrivateRoute';
import LoadingAnimation from './components/LoadingAnimation/LoadingAnimation';
import './assets/scss/app.scss';
import './assets/scss/customerDashboard.scss';
import './assets/scss/menu2.scss';
// import Dashboard from "./components/Dashboard/Dashboard";
import SignIn from './components/Auth/SignIn/SignIn';
import { getCustomerData } from './utils/Constants';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;

// import ChangePassword from "./components/Dashboard/ChangePassword";

const LazyDashboard = lazy(() => import('./components/Dashboard/Dashboard'));
// const LazySignIn = lazy(() => import("./components/Auth/SignIn/SignIn"));
const LazyChangePassword = lazy(() => import('./components/Dashboard/ChangePassword'));
const LazyCompanyRegistration = lazy(
  () => import('./components/CompanyRegistration/CompanyRegistration')
);
const LazyRateEnquiryFeedbackForm = lazy(() => import('./components/RateEnquiryFeedbackForm'));
const LazyAboutUs = lazy(() => import('./components/AboutUs/AboutUs'));
const LazyPrivacyPolicy = lazy(() => import('./components/PrivacyPolicy/PrivacyPolicy'));
const LazyMyQuery = lazy(() => import('./components/MyQuery'));
const LazyBookingForm = lazy(() => import('./components/BookingForm/BookingForm'));
const LazyBookingResponse = lazy(() => import('./components/BookingForm/BookingResponse'));
const LazyManualResponseForm = lazy(() => import('./components/RateWizard/ManualResponseForm'));

const LazyCustomerDashboard = lazy(() => import('./components/Dashboard/CustomerDashboard'));
const LazyForgotPassword = lazy(() => import('./components/ForgotPassword/ForgotPassword'));
const LazyAcceptTermsAndConditions = lazy(
  () => import('./components/Dashboard/AcceptTermsAndConditions')
);

const LazyCustomerKycOuterForm = lazy(
  () => import('./components/CustomerKyc/CustomerKycOuterForm')
);
const LazyThankyouPage = lazy(() => import('./components/Auth/NotFound/ThankyouPage'));
const LazySuperAdmin = lazy(() => import('./components/SuperAdminForm/SuperAdmin'));
const LazyInsertAgreementForm = lazy(
  () => import('./components/InsertAgreementForm/InsertAgreementForm')
);

class App extends Component {
  constructor() {
    super();
    let tk = localStorage.getItem('token');
    if (tk) {
      this.state = {
        isloggedIn: true,
        categoryCode: null,
        showModal: false
      };
      this.timeoutId = null;
    } else {
      this.state = {
        isloggedIn: false,
        categoryCode: null,
        showModal: false
      };
      this.intervalId = null;
    }
  }

  componentDidMount() {
    if (this.state.isloggedIn) {
      this.getRole();
      !this.props.modules && this.props.getModules();
      // const ckyc = getCustomerData();
    }
  }

  componentDidUpdate(pP, pS) {
    if (pS.isloggedIn !== this.state.isloggedIn) {
      this.getRole();
      !this.props.modules && this.props.getModules();
    }
  }

  checkTAndC = () => {
    const url = this.props.history?.location?.pathname;
    if (
      (localStorage.getItem('isSystemVerified') !== '1' ||
        localStorage.getItem('isEmailVerified') !== '1' ||
        localStorage.getItem('hasAcceptedSystemTAndC') !== 'true') &&
      this.state.categoryCode !== 'CAT_ADM' &&
      url !== '/tandc' &&
      this.state.isloggedIn
    ) {
      this.props.history.push(`/tandc`);
    }
  };

  getRole = () => {
    const { history } = this.props;
    const customerKyc = JSON.parse(localStorage.getItem('customerKyc'));
    const url = history.location ? history.location?.pathname : '';

    // Check if customerKyc exists
    if (customerKyc) {
      const categoryCode = customerKyc.companyCategory.categoryCode;
      this.setState({ categoryCode }, () => {
        this.checkTAndC();
      });
    }
    // Check for logged in user, missing modulesPermissions and not in super-user-dashboard page
    //temporary fixes as no user needs to go to the super-user-dashboard page on any case other than the superadmin.
    else if (
      this.state.isloggedIn &&
      !localStorage.getItem('modulesPermissions') &&
      localStorage.getItem('loggedInUserId') == 1 &&
      url !== '/super-user-dashboard'
    ) {
      history.push(`/super-user-dashboard`);
    }
  };

  checkConditions = () => {
    if (
      localStorage.getItem('isSystemVerified') === '1' &&
      localStorage.getItem('isEmailVerified') === '1' &&
      localStorage.getItem('hasAcceptedSystemTAndC') === 'true'
    ) {
      return true;
    } else {
      return false;
    }
  };

  checkFirstLogin = () => {
    return localStorage.getItem('isFirstLogin') === 'true' ? true : false;
  };

  successSetter = () => {
    this.props.setSubModuleToggle(null);
    this.setState({ isloggedIn: true }, () => {
      !this.props.modules && this.props.getModules();
    });
  };

  render() {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Switch>
          <Suspense fallback={<LoadingAnimation />}>
            <Route
              path="/"
              exact
              render={() =>
                this.state.isloggedIn && !this.checkFirstLogin() ? (
                  <>
                    <LazyDashboard modules={this.props.modules} />
                  </>
                ) : this.checkFirstLogin() ? (
                  <LazyChangePassword />
                ) : (
                  <SignIn successSetter={this.successSetter} />
                )
              }
            />

            <PrivateRoute
              authed={this.state.isloggedIn}
              modules={this.props.modules}
              path="/super-user-dashboard"
              component={LazySuperAdmin}
            />
            <PrivateRoute
              authed={this.state.isloggedIn}
              modules={this.props.modules}
              path="/tandc"
              component={LazyAcceptTermsAndConditions}
            />

            <PrivateRoute
              authed={this.state.isloggedIn}
              modules={this.props.modules}
              path="/insert-agreement-form"
              component={LazyInsertAgreementForm}
            />

            <PrivateRoute
              authed={this.state.isloggedIn}
              modules={this.props.modules}
              path="/dashboard"
              component={
                (this.checkConditions() && localStorage.getItem('isFirstLogin') === 'false') ||
                this.state.categoryCode === 'CAT_ADM'
                  ? LazyDashboard
                  : !this.checkConditions()
                    ? LazyAcceptTermsAndConditions
                    : LazyChangePassword
              }
            />
            <Route
              path="/login"
              render={() => <SignIn successSetter={this.successSetter} />}
            ></Route>
            <Route path="/thankYouPage" component={LazyThankyouPage}></Route>
            <Route
              path="/customerKyc/:user_id?/:email?/:invitation_id?"
              component={LazyCustomerKycOuterForm}
            ></Route>
            <Route
              // authed={this.state.isloggedIn}
              path="/update-password/:uname"
              component={UpdatePassword}
            />
            <PrivateRoute
              authed={this.state.isloggedIn}
              path="/change-password"
              component={LazyChangePassword}
            />
            <Route path="/forgotPassword" component={LazyForgotPassword}></Route>
            <Route path="/customerDashboard" component={LazyCustomerDashboard}></Route>
            <Route path="/rateEnquiryResponseForm" component={LazyManualResponseForm} />
            <Route path="/booking-response/:refNo" component={LazyBookingResponse} />
            <Route path="/booking-form" component={LazyBookingForm} />
            <Route path="/myQuery" component={LazyMyQuery}></Route>
            <Route path="/privacy-policy" component={LazyPrivacyPolicy}></Route>
            <Route path="/aboutus" component={LazyAboutUs}></Route>
            <Route path="/feedback-form" component={LazyRateEnquiryFeedbackForm}></Route>
            <Route path="/registrationForm" component={LazyCompanyRegistration}></Route>
            <Route path="*" render={() => <NotFound />} />
          </Suspense>
        </Switch>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = (state, props) => ({
  modules: state.modules.modules
});

const mapActionToProps = {
  getModules,
  setSubModuleToggle
};

export default connect(mapStateToProps, mapActionToProps)(withRouter(App));
