//actions
const LOAD_NOTIFICATIONS = 'NOTIFICATIONS/LOAD';
const LOAD_NOTIFICATIONS2 = 'NOTIFICATIONS/LOAD2';
const LOAD_NOTIFICATIONCOUNT2 = 'NotificationCount/LOAD2';
const LOAD_NOTIFICATIONCOUNT = 'NotificationCount/LOAD';

const LOAD_NOTIFICATIONTOTALCOUNT = 'NotificationTotalCount/LOAD';
const LOAD_NOTIFICATIONTOTALCOUNT2 = 'NotificationTotalCount/LOAD2';
const UPDATE_NOTIFICATIONS2 = 'UPDATE_NOTIFICATIONS2';

const LOAD_BADGE_NOTIFICATION = 'BADGE_NOTIFICATION/LOAD';
const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
const RESET_NOTIFICATION2 = 'RESET_NOTIFICATION2';
const All_READ_MANUAL = 'All_READ_MANUAL';

//actions-creator
export function loadNotifications(notifications) {
  return { type: LOAD_NOTIFICATIONS, payload: notifications };
}

export function loadNotifications2(notifications, type) {
  return { type: LOAD_NOTIFICATIONS2, payload: { notifications, type } };
}
export function loadNotificationCount(count) {
  return { type: LOAD_NOTIFICATIONCOUNT, payload: count };
}

export function loadNotificationCount2(count) {
  return { type: LOAD_NOTIFICATIONCOUNT2, payload: count };
}

export function loadNotificationTotalCount(count) {
  return { type: LOAD_NOTIFICATIONTOTALCOUNT, payload: count };
}

export function loadNotificationTotalCount2(count) {
  return { type: LOAD_NOTIFICATIONTOTALCOUNT2, payload: count };
}
export function loadBadgeNotification(notifications) {
  return { type: LOAD_BADGE_NOTIFICATION, payload: notifications };
}
//reset navHeader Ducks
export function resetNofificationDucks() {
  return { type: RESET_NOTIFICATION, payload: null };
}

export function resetSecondNotification() {
  return { type: RESET_NOTIFICATION2, payload: null };
}

export function allReadManualFun() {
  return { type: All_READ_MANUAL, payload: null };
}

//reducer
export default function reducer(
  state = {
    notifications: null,
    badgeNotifications: [],
    unreadNotificationCount: 0,
    unreadNotificationCount2: 0,
    totalCount: 0,
    totalCount2: 0,
    // notifications2: null,
    notifications2: [],
    allReadManual: false
  },
  action
) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return { ...state, notifications: action.payload, allReadManual: false };
    case LOAD_NOTIFICATIONS2:
      return {
        ...state,
        notifications2: action.payload.notifications,
        allReadManual: action.payload.type == 'ALLREAD' ? state.allReadManual : false
      };
    case LOAD_NOTIFICATIONCOUNT:
      return { ...state, unreadNotificationCount: action.payload };
    case LOAD_NOTIFICATIONCOUNT2:
      return { ...state, unreadNotificationCount2: action.payload };
    case UPDATE_NOTIFICATIONS2:
      return { ...state, notifications2: action.payload };

    case LOAD_NOTIFICATIONTOTALCOUNT:
      return { ...state, totalCount: action.payload };
    case LOAD_NOTIFICATIONTOTALCOUNT2:
      return { ...state, totalCount2: action.payload };
    case LOAD_BADGE_NOTIFICATION:
      return { ...state, badgeNotifications: action.payload };
    case All_READ_MANUAL:
      return { ...state, allReadManual: true };
    case RESET_NOTIFICATION:
      return {
        ...state,
        notifications: null,
        badgeNotifications: [],
        unreadNotificationCount: 0,
        totalCount: 0
      };
    case RESET_NOTIFICATION2:
      return {
        ...state,
        notifications2: null,
        badgeNotifications: [],
        unreadNotificationCount2: 0,
        totalCount2: 0
      };
    default:
      return state;
  }
}

export function storeNotifications(notifications) {
  return (dispatch) => {
    dispatch(loadNotifications(notifications));
  };
}

export function storeNotificationCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationCount(count));
  };
}

export function storeNotificationTotalCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationTotalCount(count));
  };
}

//////////////////////////////////////////////////for page /////////////////////////////////////////////////////////
export function storeNotifications2(notifications, type) {
  // console.log(notifications)
  return (dispatch) => {
    dispatch(loadNotifications2(notifications, type));
  };
}

export function storeNotificationCount2(count) {
  // console.log(count)
  return (dispatch) => {
    dispatch(loadNotificationCount2(count));
  };
}

export function storeNotificationTotalCount2(count) {
  return (dispatch) => {
    dispatch(loadNotificationTotalCount2(count));
  };
}

export const updateNotifications2 = (notifications) => ({
  type: UPDATE_NOTIFICATIONS2,
  payload: notifications
});
//////////////////////////////////////////////////for page /////////////////////////////////////////////////////////

export function storeBadgeNotifications(notifications) {
  return (dispatch) => {
    dispatch(loadBadgeNotification(notifications));
  };
}

export function resetNotificationReduxState() {
  return (dispatch) => {
    dispatch(resetNofificationDucks());
  };
}

export function resetNotification2() {
  return (dispatch) => {
    dispatch(resetSecondNotification());
  };
}

export function allReadMan() {
  return (dispatch) => {
    dispatch(allReadManualFun());
  };
}
