//actions
const LOAD_NOTIFICATIONS = 'NOTIFICATIONS/LOAD';
const UPDATE_NOTIFICATON = 'UPDATE/NOTIFICATION';
const UPDATE_READ_NOTIFICATON = 'UPDATE_READ_NOTIFICATON';
const LOAD_NOTIFICATIONCOUNT = 'NOTIFICATIONCOUNT/LOAD';
const LOAD_NOTIFICATIONTOTALCOUNT = 'NOTIFICATIONTOTALCOUNT/LOAD';
const RESET_NOTIFICATION = 'RESET_NOTIFICATION';
const All_READ = 'All_READ';

//actions-creator
export function loadNotifications(notifications) {
  return { type: LOAD_NOTIFICATIONS, payload: notifications };
}

export function updateStoreNotificationload(notifications) {
  return { type: UPDATE_NOTIFICATON, payload: notifications };
}

export function readStoreUpdateload(notifications) {
  return { type: UPDATE_READ_NOTIFICATON, payload: notifications };
}

export function loadNotificationCount(count) {
  return { type: LOAD_NOTIFICATIONCOUNT, payload: count };
}

export function loadNotificationTotalCount(count) {
  return { type: LOAD_NOTIFICATIONTOTALCOUNT, payload: count };
}

//reset navHeader Ducks
export function resetNofificationDucks() {
  return { type: RESET_NOTIFICATION, payload: null };
}

//reset navHeader Ducks
export function markAllReadBadge() {
  return { type: All_READ, payload: null };
}

//reducer
export default function reducer(
  state = {
    notifications: [],
    unreadNotificationCount: 0,
    totalCount: 0,
    notificationUpdate: {}
  },
  action
) {
  switch (action.type) {
    case LOAD_NOTIFICATIONS:
      return { ...state, notifications: action.payload, allReadManual: false };
    case UPDATE_NOTIFICATON:
      let data = state.notifications;
      data.pop();
      data.unshift(action.payload);
      return {
        ...state,
        notifications: data,
        notificationUpdate: action.payload,
        totalCount: parseInt(state.totalCount) + 1,
        unreadNotificationCount: parseInt(state.unreadNotificationCount) + 1
      };
    case UPDATE_READ_NOTIFICATON:
      let allData = state.notifications.map((item) => {
        if (item.id == action.payload.id) {
          return { ...item, read: true };
        }
        return item;
      });
      return {
        ...state,
        notifications: allData,
        unreadNotificationCount:
          parseInt(state.unreadNotificationCount) === 0
            ? 0
            : parseInt(state.unreadNotificationCount) - 1
      };

    case LOAD_NOTIFICATIONCOUNT:
      return { ...state, unreadNotificationCount: action.payload };
    case LOAD_NOTIFICATIONTOTALCOUNT:
      return { ...state, totalCount: action.payload };
    case All_READ:
      return {
        ...state,
        notifications: state.notifications.map((item) => ({ ...item, read: true })),
        unreadNotificationCount: 0
      };
    case RESET_NOTIFICATION:
      return {
        ...state,
        notifications: [],
        unreadNotificationCount: 0,
        totalCount: 0,
        allReadManual: false
      };

    default:
      return state;
  }
}

export function storeNotifications(notifications) {
  return (dispatch) => {
    dispatch(loadNotifications(notifications));
  };
}

export function updateStoreNotification(notifications) {
  return (dispatch) => {
    dispatch(updateStoreNotificationload(notifications));
  };
}

export function readStoreUpdate(notifications) {
  return (dispatch) => {
    dispatch(readStoreUpdateload(notifications));
  };
}

export function storeNotificationCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationCount(count));
  };
}

export function storeNotificationTotalCount(count) {
  return (dispatch) => {
    dispatch(loadNotificationTotalCount(count));
  };
}

export function resetNotificationReduxState() {
  return (dispatch) => {
    dispatch(resetNofificationDucks());
  };
}
